import React from "react"
import "./dotsIT.scss"

const DotsIT = props => {
  return (
    <div className={`dev-team__steps stepsIT`}>
      <div className={`groupDots dotsIT1 ${props.show}`}>
        {Array(6)
          .fill(1)
          .map((block, index) => (
            <div key={index} className="dot" />
          ))}
      </div>

      <div className={`groupDots dotsIT2 ${props.show}`}>
        {Array(6)
          .fill(1)
          .map((block, index) => (
            <div key={index} className="dot" />
          ))}
      </div>
      <div className={`groupDots dotsIT3 ${props.show}`}>
        {Array(6)
          .fill(1)
          .map((block, index) => (
            <div key={index} className="dot" />
          ))}
      </div>
    </div>
  )
}

export default DotsIT

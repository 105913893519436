import React from "react"
import { withTranslation } from "react-i18next"
// import { dataOurITProcess } from "../../../../../data/Services/dataIT"

const ContentIT = props => {
  const { t } = props
  return (
    <>
      {t("src.pages.ITConsultingPage.ourITConsultingProcess.data").map(
        (block, index) => (
          <div key={index.toString()} className="col col-3">
            <header className="col-header">
              <div className={`header-title ${props.show}`}>{block.title}</div>
            </header>
            <footer className="col-footer">
              <ul className="footer-list">
                <li>{block.content}</li>
              </ul>
            </footer>
          </div>
        )
      )}
    </>
  )
}

export default withTranslation()(ContentIT)

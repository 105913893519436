import React, { useState, useEffect } from "react"
import { withTranslation } from "react-i18next"
import { useUnmount } from "react-use"
// import { dataTitleITProcess } from "../../../../data/Services/dataIT"
import "./ourITprocess.scss"
import DotsIT from "./dotsIT/dotsIT"
import ContentIT from "./contentIT/contentIT"
// import DotsMobile from "./dotsMobile/dotsMobile"
// import ContentMobile from './contentMobile/contentMobile'
const OurITProcess = props => {
  const { t } = props
  const [addictionalClasses, setClasses] = useState("")

  function checkScreen() {
    if (window.scrollY > 1800 && window.scrollY < 2700) setClasses("in-view")
    else {
      setClasses("")
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", checkScreen)
  }, [])

  useUnmount(() => {
    window.removeEventListener("scroll", checkScreen)
  })

  return (
    <section className="service-dev-team">
      <div className="container">
        <h2 className="dev-team__title">
          {t("src.pages.ITConsultingPage.ourITConsultingProcess.title")}
        </h2>
        <p className="dev-team__description">
          {t("src.pages.ITConsultingPage.ourITConsultingProcess.content")}
        </p>
        <div className="coverITprocess">
          <div className="dev-team__row dev-IT">
            <DotsIT show={addictionalClasses} />
            <ContentIT show={addictionalClasses} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default withTranslation()(OurITProcess)

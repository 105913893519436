import React from "react"
import { withTranslation } from "react-i18next"
// import {dataITTitle} from '../../../../data/Services/dataIT'
import "./ITpageTitle.scss"
const ITPageTitle = props => {
  const { t } = props
  return (
    <section className="hero hero--600 bgIT">
      <div className="container">
        <div className="hero__content">
          <h1 className="title title--uppercase">
            <span className="title__preheader title__preheader--tag">
              {t("src.pages.ITConsultingPage.title")}
            </span>
            {t("src.pages.ITConsultingPage.subtitle")}
          </h1>
          <p className="hero__lead">
            {t("src.pages.ITConsultingPage.content")}
          </p>
        </div>
      </div>
    </section>
  )
}

export default withTranslation()(ITPageTitle)

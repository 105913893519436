import React from "react"
import { withTranslation } from "react-i18next"
// import { dataTechITtitle } from "../../../../data/Services/dataIT"
import TheLastTechContent from "../../mobileApp/theLastTech/theLastTechContent/theLastTechContent"

const TheLastTechIT = props => {
  const { t } = props
  return (
    <section className="latest-teach">
      <div className="container">
        <h2 className="teach-title">
          {t("src.pages.ITConsultingPage.theLastTech.title")}
        </h2>
        <p className="teach-description">
          {t("src.pages.ITConsultingPage.theLastTech.content")}
        </p>
        <TheLastTechContent />
      </div>
    </section>
  )
}

export default withTranslation()(TheLastTechIT)

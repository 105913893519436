import React, { Component } from "react"
import Head from "../../head"
import Layout from "../../layout/layout.higherComponent"
import ClientsServices from "../servicesHomePage/servicesClients/clientsServices"
import TalkToOurTeam from "../../homepageInnos/about/TalkToOurTeam"
import TheLastTechIT from "./TheLastTech/theLastTechIT"
import ITPageTitle from "./ITpageTitle/ITpageTitle"
import OurITServices from "./ourITservices/ourITservices"
import GetStarted from "./getStarted/getStarted"
import OurITProcess from "./ourITprocess/ourITprocess"

class ITpage extends Component {
  constructor(props) {
    super(props)
    this.statsRef = React.createRef()
  }
  handleScroll = e => {
    e.preventDefault()
    window.scrollTo({
      top: this.statsRef.current.offsetTop,
      behavior: "smooth"
    })
  }
  render() {
    return (
      <Layout>
        <Head title="IT Consulting" />
        <ITPageTitle />
        <ClientsServices />
        <TalkToOurTeam />
        <OurITServices />
        <OurITProcess />
        <GetStarted />
        <TheLastTechIT />
        {/* <div style={{ paddingTop: "70px" }}>
          <Contact />
        </div> */}
      </Layout>
    )
  }
}

export default ITpage

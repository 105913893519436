import React from "react"
import ITpage from '../components/servicesPage/ITServices/ITpage';

const ITcontainter = () => {
  return (
    <ITpage/>
  )
}

export default ITcontainter

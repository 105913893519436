import React from "react"
import { withTranslation } from "react-i18next"
import "./getStarted.scss"
import { Link } from "gatsby"

const GetStarted = props => {
  const { t } = props
  return (
    <section className="service-cta service-cta--it-consulting">
      <div className="service-cta__text-area service-cta__text-area--it-consulting">
        <h3>{t("src.pages.ITConsultingPage.getStarted.title")}</h3>
        <p className="service-cta__white">
          {t("src.pages.ITConsultingPage.getStarted.content")}
        </p>
        <Link className="get-touch btn" to="/contactus" onClick={props.clicked}>
          {t("src.pages.ITConsultingPage.getStarted.talkToUs")}
        </Link>
      </div>
    </section>
  )
}

export default withTranslation()(GetStarted)
